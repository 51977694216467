import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading: "How should markers and signs be used?",
    body: (
      <>
        <ul>
          <li>
            <strong>Floor Markers</strong>:{" "}
            <OutboundLink href="https://www.osha.gov/SLTC/covid-19/retail.html">
              As per OSHA
            </OutboundLink>
            , floor markers can be used to demarcate six-foot distances to
            ensure that all workers and customers maintain social distance.
          </li>
          <li>
            <strong>Rope and Stanchion Systems</strong>: This approach can be
            coupled with rope-and-stanchion systems that create a physical
            6-foot barrier for queues.
          </li>
          <li>
            <strong>Signage</strong>: Signage can be used to instruct
            individuals waiting in line to remain 6 feet back from work areas
            and cash registers.
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: "What should you look for when shopping for markers and signs?",
    body: (
      <>
        <p>
          While there is no quality standard for this equipment, you may want to
          consider:
        </p>
        <ul>
          <li>
            <strong>Visibility</strong>: Is it colorful enough to draw the eye,
            and large enough to see?
          </li>
          <li>
            <strong>Durability</strong>: How long will it last in a high-traffic
            environment?
          </li>
          <li>
            <strong>Stability</strong>: In the case of signage and
            rope-and-stanchion systems, is the equipment designed to stay
            upright, or is there a risk of it falling and becoming hazardous?
          </li>
        </ul>
      </>
    ),
  },
]

const SignageContent = () => <Content sections={sections} />

export default SignageContent
