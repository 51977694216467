import React from "react"

const Fieldset = ({
  children,
  className = "",
  inline = false,
  alignItems,
  errorMessage,
  ...props
}) => {
  const baseClassName = inline ? "inline-block" : "mb-5"
  return (
    <fieldset className={`${baseClassName} ${className || ""}`} {...props}>
      <div className={`flex ${alignItems || "items-center"}`}>{children}</div>
      <div className="text-red-400">{errorMessage}</div>
    </fieldset>
  )
}

export default Fieldset
