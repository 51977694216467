import React from "react"

const Content = ({ sections }) => {
  return (
    <>
      {sections.map(({ heading, body }) => {
        return (
          <React.Fragment key={heading}>
            <h3>{heading}</h3>
            {body}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Content
