import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/signage"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
const { calculator, faq } = locale
const log = logger.extend("calculator:signage")

const SECTION_KEY = "signage"

const RESET_KEYS = [
  "signage_number_of_lines",
  "signage_length_per_line",
  "signage_signage_per_line",
]

const CalculatorSignage = () => {
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length

  if (hasErrors) {
    log("Form errors:", errors)
  }

  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.signage}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.signage}</h2>

        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="signage_floor_markers_total" />
            <div className="text-sm">Floor markers</div>
          </div>
          <div>
            <CalculatorTotal totalKey="signage_signage_total" />
            <div className="text-sm">Signage</div>
          </div>
          <div>
            <CalculatorTotal totalKey="signage_rope_total" />
            <div className="text-sm">Rope/stanchion systems</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>
              <p className="mb-5">My business has:</p>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="signage_number_of_lines"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="signage_number_of_lines"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="signage_number_of_lines">
                  lines/queues that need markers, signage, rope, and stanchion
                  systems
                </label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="signage_length_per_line"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="signage_length_per_line"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="signage_length_per_line" className="flex-grow">
                  floor length of each line/queue in feet
                </label>
              </Fieldset>

              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>
            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorSignage
